import moment from 'moment';

import { englishCalendar } from './englishCalendar';

moment.locale('en-US', {
  calendar: englishCalendar,
  longDateFormat: {
    LT: 'h:mm A',
    LTS: 'h:mm:ss A',
    L: 'MM/DD/YYYY',
    LL: 'MMMM D, YYYY',
    LLL: 'MMMM D, YYYY LT',
    LLLL: 'MMMM D',
  },
  ordinal(number) {
    const b = number % 10;
    const output =
      ~~((number % 100) / 10) === 1
        ? 'th'
        : b === 1
        ? 'st'
        : b === 2
        ? 'nd'
        : b === 3
        ? 'rd'
        : 'th';
    return String(number) + output;
  },
});
